import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import _debounce from 'lodash/debounce';

export default () => {
  // New create carousel function and smartedit update event callback
  const createCarousel = async () => {
    const item = document.querySelector('.js-ken-pdp__recipes__swiper');
    if (!item) return;
    const isDesktop = window.matchMedia('(min-width:768px)').matches;
    const scrollbar = item.querySelector('.swiper-scrollbar');

    const LazySwiperLib = await LazySwiperController();
    let slider = new LazySwiperLib(item, {
      slidesPerView: 2,
      spaceBetween: 10,
      scrollbar: {
        el: scrollbar,
        hide: false,
      },
    });

    if (isDesktop) {
      slider.destroy(true, true);
    }

    window.addEventListener(
      'resize',
      _debounce(() => {
        const desktop = window.matchMedia('(min-width:768px)').matches;
        if (desktop) {
          slider.destroy(true, true);
        } else if (slider.destroyed) {
          slider = new LazySwiperLib(item, {
            slidesPerView: 2,
            spaceBetween: 10,
            scrollbar: {
              el: scrollbar,
              hide: false,
            },
          });
        }
      }, 100),
    );
  };

  createCarousel();
};

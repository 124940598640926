/* eslint-disable no-new */
import { openZoomGallery } from '@/pdp-kenwood/Controllers/PdpZoomGalleryController';
import { bindGtmEventsForPlyrPlayer } from '@/utils/Controllers/GTM/Operations/Video';
import { LazyPlyr } from '@/utils/Controllers/LazyPlyrController';
import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import _debounce from 'lodash/debounce';

const GALLERY_THUMBS_SELECTOR = '.js-ken-pdp__gallery__thumbs-images';
const GALLERY_MAIN_IMAGES_SELECTOR = '.js-ken-pdp__gallery__main-images';
const GALLERY_MAIN_VIDEO_SELECTOR = '.js-ken-pdp__gallery__main-video';
const VIDEO_THUMB_SELECTOR = '.js-ken-pdp__gallery__thumbs-video-img-wrapper';
const VIDEO_PLYR_SELECTOR = '.js-pdp-video-player';

const ACTIVE_CLASS = '--active';
const HIDDEN_CLASS = '--hidden';

const DIRECTION_H = 'horizontal';
const DIRECTION_V = 'vertical';

const isDesktop = () => window.innerWidth >= 1024;

const calculateDirection = () => (isDesktop() && DIRECTION_V) || DIRECTION_H;

export class Gallery {
  constructor({ selector, currentSlideIndex = null, hasZoom = false }) {
    this.wrapper = document.querySelector(selector);
    if (this.wrapper) {
      this.currentSlideIndex = currentSlideIndex;
      this.hasZoom = hasZoom;
      this.initGallery();
      this.initVideo();
    }
  }

  async initGallery() {
    this.galleryThumbs = this.wrapper.querySelector(GALLERY_THUMBS_SELECTOR);
    this.galleryMainImages = this.wrapper.querySelector(
      GALLERY_MAIN_IMAGES_SELECTOR,
    );
    if (this.galleryThumbs && this.galleryMainImages) {
      const LazySwiperLib = await LazySwiperController();
      this.thumbsSlider = new LazySwiperLib(this.galleryThumbs, {
        a11y: {
          enabled: true,
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        slidesPerView: 'auto',
        watchOverflow: true,
        preloadImages: false,
        slideToClickedSlide: true,
        direction: calculateDirection(),
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },
      });

      this.mainSlider = new LazySwiperLib(this.galleryMainImages, {
        a11y: {
          enabled: true,
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        slidesPerView: 1,
        watchOverflow: true,
        preloadImages: false,
        watchSlidesVisibility: true,
      });

      if (this.currentSlideIndex) {
        this.thumbsSlider.slideTo(this.currentSlideIndex);
        this.mainSlider.slideTo(this.currentSlideIndex);
      }

      this.thumbsSlider.el
        .querySelectorAll('.swiper-slide')
        .forEach((slide, index) => {
          slide.addEventListener('focus', () => {
            this.thumbsSlider.slideTo(index);
          });
          slide.addEventListener('click', () => this.clickOnThumb());
          slide.addEventListener('keydown', e => {
            if (e.code === 'Enter' || e.code === 'Space') {
              e.preventDefault();
              e.stopPropagation();
              this.clickOnThumb();
            }
          });
        });

      this.mainSlider.on('slideChange', () => this.slideThumb());

      this.mainSlider.el
        .querySelectorAll('.swiper-slide')
        .forEach((slide, index) => {
          slide.addEventListener('focus', () => this.mainSlider.slideTo(index));
          if (this.hasZoom) {
            slide.addEventListener('click', () => this.handleOpenZoom());
            slide.addEventListener('keydown', e => {
              if (e.code === 'Enter' || e.code === 'Space') {
                e.preventDefault();
                e.stopPropagation();
                this.handleOpenZoom();
              }
            });
          }
        });

      const resizeHandler = _debounce(() => this.changeDirection(), 250);
      window.addEventListener('resize', resizeHandler);
    }
  }

  clickOnThumb() {
    const { clickedIndex } = this.thumbsSlider;
    this.mainSlider.el.classList.remove(HIDDEN_CLASS);
    this.mainSlider.slideTo(clickedIndex);
    const mainVideoWrapperEl = this.wrapper.querySelector(
      GALLERY_MAIN_VIDEO_SELECTOR,
    );
    if (mainVideoWrapperEl) {
      mainVideoWrapperEl.classList.add(HIDDEN_CLASS);
      this.stopVideo();
    }
  }

  handleOpenZoom() {
    const { activeIndex } = this.mainSlider;
    sessionStorage.setItem('index', activeIndex);
    openZoomGallery(activeIndex);
  }

  slideThumb() {
    const { realIndex } = this.mainSlider;
    this.removeActiveSlides(realIndex);
    this.thumbsSlider.slideTo(realIndex, 0);
  }

  changeDirection() {
    this.thumbsSlider.changeDirection(calculateDirection());
  }

  removeActiveSlides(activeIndex) {
    if (this.thumbsSlider) {
      const { slides } = this.thumbsSlider;
      Array.from(slides).forEach(s => s.classList.remove(ACTIVE_CLASS));
      if (activeIndex > -1) {
        slides[activeIndex].classList.add(ACTIVE_CLASS);
      }
    }
  }

  initVideo() {
    this.videoThumbEl = this.wrapper.querySelector(VIDEO_THUMB_SELECTOR);
    if (this.videoThumbEl) {
      this.galleryMainVideo = this.wrapper.querySelector(
        GALLERY_MAIN_VIDEO_SELECTOR,
      );
      this.videoThumbEl.addEventListener('click', () =>
        this.handleVideoThumbClick(),
      );

      // show video if no images are present
      if (!this.galleryThumbs) this.bindVideo(false);
    }
  }

  handleVideoThumbClick() {
    if (this.videoPlyr) this.playVideo();
    else this.bindVideo(true);
  }

  bindVideo(autoplay) {
    this.showVideo();
    const videoEl = this.wrapper.querySelector(VIDEO_PLYR_SELECTOR);
    if (videoEl) {
      (async self => {
        const Plyr = await LazyPlyr();
        self.videoPlyr = new Plyr(videoEl, { autoplay });
        self.bindEvents();
      })(this);
    }
  }

  bindEvents() {
    if (this.videoPlyr) {
      const { videoId, videoTitle, videoUrl } = this.videoPlyr.config;
      const gtmInfo = { videoId, videoTitle, videoUrl };
      bindGtmEventsForPlyrPlayer(this.videoPlyr, gtmInfo);
    }
  }

  showVideo() {
    if (this.galleryMainImages)
      this.galleryMainImages.classList.add(HIDDEN_CLASS);
    if (this.galleryMainVideo)
      this.galleryMainVideo.classList.remove(HIDDEN_CLASS);
    this.removeActiveSlides();
  }

  stopVideo() {
    if (this.videoPlyr) this.videoPlyr.stop();
  }

  playVideo() {
    if (this.videoPlyr) {
      this.showVideo();
      this.videoPlyr.play();
    }
  }
}

export default () => {
  new Gallery({ selector: '#js-ken-pdp__gallery', hasZoom: true });
};

const initSpecificationsShowMore = item => {
  const button = document.querySelector('.js-show-more-button');
  const showMore = document.querySelector('.js-show-more-text--more');
  const showLess = document.querySelector('.js-show-more-text--less');
  const icon = document.querySelector('.js-show-more-icon');

  let expanded = false;

  item.style.maxHeight = 0;

  // eslint-disable-next-line no-unused-expressions
  button?.addEventListener('click', () => {
    if (expanded) {
      item.style.maxHeight = 0;

      icon.classList.toggle('--rotated');
      showMore.classList.toggle('--hidden');
      showLess.classList.toggle('--hidden');

      expanded = false;
    } else {
      const { scrollHeight } = item;

      item.style.maxHeight = `${scrollHeight}px`;

      icon.classList.toggle('--rotated');
      showMore.classList.toggle('--hidden');
      showLess.classList.toggle('--hidden');

      expanded = true;
    }
  });
};

export default () => {
  const specifications = document.querySelector('.js-show-more');
  // eslint-disable-next-line no-unused-expressions
  specifications && initSpecificationsShowMore(specifications);
};

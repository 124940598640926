import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

const getJourney = isOwnerJourney => (isOwnerJourney ? 'owner' : 'wannabe');

export const pushStep0 = isOwnerJourney => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.FUNNEL(
      getJourney(isOwnerJourney),
    ),
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.STEP_0,
  });
};

export const pushStep1 = isOwnerJourney => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.FUNNEL(
      getJourney(isOwnerJourney),
    ),
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.STEP_1,
  });
};

export const pushStep2 = isOwnerJourney => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.FUNNEL(
      getJourney(isOwnerJourney),
    ),
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.STEP_2,
  });
};

export const pushStep3 = isOwnerJourney => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.FUNNEL(
      getJourney(isOwnerJourney),
    ),
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.STEP_3,
  });
};

export const pushStep4 = isOwnerJourney => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.FUNNEL(
      getJourney(isOwnerJourney),
    ),
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.STEP_4,
  });
};

export const pushWannabeHelpMeChoose = () => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.WANNABE_HELP_ME_CHOOSE,
    eventLabel: undefined,
  });
};

export const pushOwnerAdapterAdd = () => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.OWNER_ADAPTER_ADD,
    eventLabel: undefined,
  });
};

const pushProductSelect = (isOwnerJourney, productName = '') => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.PRODUCT_SELECT(
      getJourney(isOwnerJourney),
    ),
    eventLabel: productName,
  });
};

const pushSelectColor = (isOwnerJourney, productName = '') => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.SELECT_COLOR(
      getJourney(isOwnerJourney),
    ),
    eventLabel: productName,
  });
};

export const pushProductSelectCta = (isOwnerJourney, productName = '') => {
  if (isOwnerJourney) pushProductSelect(isOwnerJourney, productName);
  else pushSelectColor(isOwnerJourney, productName);
};

export const pushProductChange = (isOwnerJourney, productName = '') => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.PRODUCT_CHANGE(
      getJourney(isOwnerJourney),
    ),
    eventLabel: productName,
  });
};

export const pushAccessoryAdd = (
  isOwnerJourney,
  accessoryCategory = '',
  accessoryName = '',
) => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.ACCESSORY_ADD(
      getJourney(isOwnerJourney),
    ),
    eventLabel: `${accessoryCategory}-${accessoryName}`,
  });
};

export const pushAccessoryRemove = (
  isOwnerJourney,
  accessoryCategory,
  accessoryName,
) => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.ACCESSORY_REMOVE(
      getJourney(isOwnerJourney),
    ),
    eventLabel: `${accessoryCategory}-${accessoryName}`,
  });
};

export const pushAccessoryAddMore = isOwnerJourney => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.ACCESSORY_ADD_MORE(
      getJourney(isOwnerJourney),
    ),
    eventLabel: GTM.EVENT.LABEL.CTA,
  });
};

export const pushAccessoryAddMorePromoAlert = isOwnerJourney => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.ACCESSORY_ADD_MORE(
      getJourney(isOwnerJourney),
    ),
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.PROMO_ALERT,
  });
};

export const pushAdapterTwistSystem = () => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.OWNER_MODAL,
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.TWIST_SYSTEM,
  });
};

export const pushAdapterAlert = () => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.OWNER_MODAL,
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.ADAPTER_ALERT,
  });
};

export const pushAdapterInfo = () => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.OWNER_MODAL,
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.ADAPTER_INFO,
  });
};

export const pushModalPromoAlert = isOwnerJourney => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.MODAL_PROMO_ALERT(
      getJourney(isOwnerJourney),
    ),
    eventLabel: GTM.EVENT.LABEL.KENWOOD.CONFIGURATOR.PROMO_ALERT,
  });
};

export const pushConfigurePDPCta = () => {
  push({
    event: GTM.EVENT.GENERIC,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.KENWOOD.CONFIGURATOR.PDP_CTA,
    eventLabel: undefined,
  });
};

/* eslint-disable no-new */
import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import intPdpCompareController from '@/utils/Controllers/PdpCompareController';

const OPEN_CLASS = '--open';

const updateComparedItems = swiper => {
  const currEl = swiper.slides[swiper.activeIndex];
  if (currEl) {
    const { comparedProductCode } = currEl.dataset;
    Array.from(document.querySelectorAll('.js-compared-product-code')).forEach(
      item => {
        item.hidden = item.dataset.comparedProductCode !== comparedProductCode;
      },
    );
  }
};

const iniCompareCarousel = async item => {
  const nextEl = item.querySelector('.swiper-button-next');
  const prevEl = item.querySelector('.swiper-button-prev');

  const LazySwiperLib = await LazySwiperController();
  const swiper = new LazySwiperLib(item, {
    slidesPerView: 1,
    watchOverflow: true,
    spaceBetween: 20,
    navigation: {
      nextEl,
      prevEl,
    },
  });

  swiper.on('slideChange', () => updateComparedItems(swiper));
};

const toggleSpecsTable = (toggleCta, specsTable) => {
  const isOpen = specsTable.classList.contains(OPEN_CLASS);
  specsTable.classList[isOpen ? 'remove' : 'add'](OPEN_CLASS);
  toggleCta.innerHTML = toggleCta.dataset[isOpen ? 'openLabel' : 'closeLabel'];
};

const jsEventsCallback = () => {
  const compare = document.querySelectorAll('.js-ken-pdp__compare__slider');
  Array.from(compare).forEach(item => iniCompareCarousel(item));

  const specsTable = document.querySelector(
    '.js-ken-pdp__compare__table__container',
  );
  if (specsTable) {
    const toggleCta = specsTable.parentElement.querySelector(
      '.js-ken-pdp__compare__table__show-cta',
    );
    toggleCta.addEventListener('click', () =>
      toggleSpecsTable(toggleCta, specsTable),
    );
  }
};

export default () => {
  intPdpCompareController(jsEventsCallback);
};

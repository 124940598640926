import SmarteditController from '@/utils/Controllers/SmarteditController';
import findParentByClass from '@/utils/Common/FindParentByClass';

const WRAPPER_CLASS = 'js-ken-pdp__promo-banner';
const CTA_SELECTOR = '.js-ken-pdp__promo-banner__cta';
const OPEN_CLASS = '--open';

const callback = entry => {
  entry.addEventListener('click', () => {
    const el = findParentByClass(entry, WRAPPER_CLASS);
    el.classList.toggle(OPEN_CLASS);
  });
};

const init = () => {
  const entries = SmarteditController.getEditorialEntries(CTA_SELECTOR);
  Array.from(entries).forEach(callback);
};

export default () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};

/* eslint-disable no-new */
import LazySwiperController from '@/utils/Controllers/LazySwiperController';

const initRecommendationsCarousel = async item => {
  const prevEl = item.querySelector('.swiper-prev');
  const nextEl = item.querySelector('.swiper-next');
  const scrollbar = item.querySelector('.swiper-scrollbar');

  const LazySwiperLib = await LazySwiperController();
  new LazySwiperLib(item, {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 120,
    watchOverflow: true,
    preloadImages: false,
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
    scrollbar: {
      el: scrollbar,
      hide: false,
    },
    navigation: {
      nextEl,
      prevEl,
    },
  });
};

export default () => {
  const recommendations = document.querySelectorAll(
    '.js-recommendations-carousel',
  );
  Array.from(recommendations).forEach(item =>
    initRecommendationsCarousel(item),
  );
};

/* eslint-disable no-new */
import KENRelatedProductsController from '@/kenwood/Controllers/KENRelatedProductsController';
import { checkWishlistIcon } from '@/kenwood/Controllers/KENWishlistController';
import initPdpRelatedProductsController from '@/utils/Controllers/PdpRelatedProductsController';

const jsEventsCallback = () => {
  KENRelatedProductsController();
  checkWishlistIcon();
};

export default () => {
  initPdpRelatedProductsController(jsEventsCallback);
};

import { goToPage } from '@/utils/Common/Redirects';
import { pushConfigurePDPCta } from '@/utils/Controllers/GTM/Operations/Kenwood/Configurator';

const init = () => {
  const configuratorCta = document.getElementById('js-configurator-cta');
  if (configuratorCta) {
    configuratorCta.addEventListener('click', e => {
      e.preventDefault();
      pushConfigurePDPCta();
      goToPage(e.currentTarget.href);
    });
  }
};

export default init;

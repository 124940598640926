/* eslint-disable no-new */
import { initWriteReviewPopup } from '@/utils/Controllers/ReviewsController';

const WRITE_REVIEW_CTA_SELECTOR = '.js-ken-pdp__reviews__write-cta';
const SHOW_MORE_CTA_SELECTOR = '.js-ken-pdp__reviews__show-more-cta';
const BOTTOM_SELECTOR = '.js-ken-pdp__reviews__bottom';
const SINGLE_ITEM_SELECTOR = '.js-ken-pdp__reviews__list__single';
const HIDDEN_CLASS = '--hidden';
const SINGLE_ITEM_HIDDEN_SELECTOR = `${SINGLE_ITEM_SELECTOR}.${HIDDEN_CLASS}`;

const initShowMoreReviews = () => {
  const showMoreCta = document.querySelector(SHOW_MORE_CTA_SELECTOR);
  if (showMoreCta) {
    const showMoreHandler = () => {
      const itemsToShow = document.querySelectorAll(
        SINGLE_ITEM_HIDDEN_SELECTOR,
      );
      Array.from(itemsToShow).forEach(item =>
        item.classList.remove(HIDDEN_CLASS),
      );
      const bottomSection = document.querySelector(BOTTOM_SELECTOR);
      if (bottomSection.parentNode) {
        bottomSection.parentNode.removeChild(bottomSection);
      }
    };

    showMoreCta.addEventListener('click', showMoreHandler);
  }
};

export default () => {
  initShowMoreReviews();
  initWriteReviewPopup(WRITE_REVIEW_CTA_SELECTOR);
};

/* eslint-disable no-new */
import LazySwiperController from '@/utils/Controllers/LazySwiperController';

const initAwardsCarousel = async item => {
  const scrollbar = item.querySelector('.swiper-scrollbar');
  const slidesCount = item.querySelectorAll('.swiper-slide').length;

  const LazySwiperLib = await LazySwiperController();
  new LazySwiperLib(item, {
    slidesPerView: 2,
    slidesPerGroup: 2,
    watchOverflow: true,
    preloadImages: false,
    centeredSlides: slidesCount === 1,
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
    scrollbar: {
      el: scrollbar,
      hide: false,
      enabled: slidesCount > 2,
    },
  });
};

export default () => {
  const awardsCarousels = document.querySelectorAll('.js-awards-carousel');
  Array.from(awardsCarousels).forEach(item => initAwardsCarousel(item));
};

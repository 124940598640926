/* eslint-disable import/prefer-default-export,no-new */
import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import EVENTS from '@/utils/Constants/Events';
import { Gallery } from '@/pdp-kenwood/Controllers/PdpGalleryController';
import { pushGtmProductZoom } from '@/utils/Controllers/GTM/Operations/ProductZoom';

const ZOOM_WRAPPER_ID = 'js-ken-pdp__gallery-zoom__wrapper';
const ZOOM_GALLERY_WRAPPER_ID = 'js-ken-pdp__gallery-zoom';
const ZOOM_GALLERY_SELECTOR = `#${ZOOM_GALLERY_WRAPPER_ID} #js-ken-pdp__gallery-zoom`;

const CloseButton = ({ onClick }) => (
  <button
    type='button'
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '10px',
      right: '10px',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
    }}
  >
    <i
      className='dln-icn dln-icn--iconfont dln-icn--J'
      data-glyph='J'
      style={{ fontSize: '33px' }}
    >
      J
    </i>
  </button>
);

CloseButton.propTypes = {
  onClick: PropTypes.func,
};
CloseButton.defaultProps = {
  onClick: () => {},
};

const ZoomModal = ({ slideNum, content }) => {
  const handleClose = () => {
    const popup = document.querySelector('.dln-popup.dln-popup--open');
    if (popup) {
      DLG.EVE.emit(EVENTS.MODAL.CLOSE, { popup });
    }
  };

  useEffect(() => {
    DLG.EVE.emit(EVENTS.MODAL.OPEN, null, {
      fullSize: 'both',
      scrollOrigin: 'page',
    });
    new Gallery({
      selector: ZOOM_GALLERY_SELECTOR,
      currentSlideIndex: slideNum,
    });
    setTimeout(() => {
      document
        .querySelector(
          `#js-default-dln-popup #${ZOOM_GALLERY_WRAPPER_ID} button`,
        )
        .focus();
    }, 300);
  }, []);

  return (
    <div id={ZOOM_GALLERY_WRAPPER_ID} style={{ padding: '50px' }}>
      <CloseButton onClick={handleClose} />
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

ZoomModal.propTypes = {
  slideNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.string,
};
ZoomModal.defaultProps = {
  slideNum: 0,
  content: null,
};

export const openZoomGallery = currentIndexSlide => {
  const zoomWrapper = document.getElementById(ZOOM_WRAPPER_ID);
  if (zoomWrapper) {
    pushGtmProductZoom();
    const content = zoomWrapper.innerHTML;
    ReactDOM.render(
      <ZoomModal slideNum={currentIndexSlide} content={content} />,
      document.getElementById('js-dln-popup__wrapper'),
    );
  }
};

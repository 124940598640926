import findParentByClass from '../../utils/Common/FindParentByClass';
/* eslint-disable no-new */

const OPEN_CLASS = '--open';
const ACTIVE_CLASS = '--active';
const STICKY_SECTION_ID = 'js-ken-start-sticky-menu';
const STICKY_WRAPPER_SELECTOR = '.js-ken-pdp__sticky';
const TOGGLE_CTA_SELECTOR = '.js-ken-pdp__sticky__toggle';
const STICKY_LINK_CLASS = 'js-ken-pdp__sticky__link';
const STICKY_LINK_SELECTOR = `.${STICKY_LINK_CLASS}`;
const STICKY_SECTIONS = '.js-ken-pdp__sticky__sections';

const observerConfig = { rootMargin: '0px 0px 0px 0px' };

const getOffsetHeight = selector => {
  const el = document.querySelector(selector);
  return (el && el.offsetHeight) || 0;
};

const distanceToFill = el => {
  const headerH = getOffsetHeight('.js-ken-header');
  const stickyWrapperH = getOffsetHeight(STICKY_WRAPPER_SELECTOR);
  return Math.floor(
    el.getBoundingClientRect().top - headerH - stickyWrapperH - 10,
  );
};

const toggleStickyMenu = e => {
  e.target.parentNode.classList.toggle(OPEN_CLASS);
  const sections = document.querySelector(STICKY_SECTIONS);
  sections.classList.toggle(OPEN_CLASS);
};

const handleLinkClick = (e, links) => {
  e.preventDefault();
  links.forEach(link => link.classList.remove(ACTIVE_CLASS));
  const actualLink = findParentByClass(e.target, STICKY_LINK_CLASS);
  actualLink.classList.add(ACTIVE_CLASS);

  const targetSection = document.querySelector(actualLink.getAttribute('href'));
  if (targetSection) {
    window.scrollBy({
      top: distanceToFill(targetSection),
      left: 0,
      behavior: 'smooth',
    });
  }
};

const observeStickyInteraction = () => {
  const observerFn = entries => {
    const el = document.querySelector(STICKY_WRAPPER_SELECTOR);
    const sections = document.querySelector(STICKY_SECTIONS);
    const areOpen = sections.classList.contains(OPEN_CLASS);
    const { isIntersecting, boundingClientRect } = entries[0];
    const method =
      !isIntersecting && boundingClientRect.top < 0 ? 'add' : 'remove';
    el.classList[method](ACTIVE_CLASS);
    // eslint-disable-next-line no-unused-expressions
    areOpen && sections.classList.remove(OPEN_CLASS);
  };

  const stickySectionEl = document.getElementById(STICKY_SECTION_ID);
  const observer = new IntersectionObserver(observerFn, observerConfig);
  observer.observe(stickySectionEl);
};

export default () => {
  const toggleCta = document.querySelector(TOGGLE_CTA_SELECTOR);
  if (toggleCta) {
    observeStickyInteraction();
    toggleCta.addEventListener('click', toggleStickyMenu);
    const links = Array.from(document.querySelectorAll(STICKY_LINK_SELECTOR));
    links.forEach(link =>
      link.addEventListener('click', e => handleLinkClick(e, links)),
    );
  }
};
